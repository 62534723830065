<template>
  <div>


    <div :hidden="Array.isArray(data)">

      <inputTextform v-bind:label="'PMI CODE'" v-bind:var_value="data.pmi_code" />
      <inputTextform v-bind:label="'PMI NAME'" v-bind:var_value="data.pmi_name" />

      <inputTextform v-bind:label="'BATCH NUMBER'"  v-bind:var_value="data.batch" />
      <inputTextform v-bind:label="'PPO'"           v-bind:var_value="data.PPO" />


      <inputTextform v-bind:label="'PRODUCTION TYPE'" v-bind:var_value="data.production_type" />
      <inputTextform v-bind:label="'SAMPLE TYPE'"   v-bind:var_value="data.sample_type" />


      <inputDate v-bind:label="'PRODUCTION DATE'"  v-bind:var_value="data.production_date" />
      <inputDate v-bind:label="'EXPIRE DATE'"      v-bind:var_value="data.expiration_date" />

    </div>


    <div :hidden="!Array.isArray(data)">
      Total Flavors to be printed : <b>{{data.length}}</b>
    </div>
    <br/>

    <inputSelect :edit="true"
                 v-bind:label="'Quantity of Labels to be printed (*N)'"
                 v-bind:var_name="'num_labels'"
                 v-bind:var_value="num_labels"
                 v-bind:optional="true"
                 v-bind:list="total"
                 @onChangedValue="onSelectList"
    />

    <br/>

    <inputSelect :edit="true"
                 v-bind:label="'Type of Label'"
                 v-bind:var_name="'type_label'"
                 v-bind:var_value="type_label"
                 v-bind:optional="false"
                 v-bind:list="type_labels"
                 @onChangedValue="onSelectedLabelType"
    />



    <b-button @click="printAllLabels()" class="pull-right"
              variant="primary"> print </b-button>

  </div>
</template>

<style scoped>
.pull-right{
  float:right;
}
</style>

<script>

import utils from "@/utils";
import inputTextform from '@/views/components/forms/Form.input.text'
import inputDate from '@/views/components/forms/Form.input.date'
import inputSelect from '@/views/components/forms/Form.input.select'
import axios from "axios"
import moment from 'moment';
import {mapActions} from "vuex";

export default {
  components: {
    inputTextform,
    inputDate,
    inputSelect
  },
  props: {
    data: Object
  },
  data() {
    return {
      total: utils.range(1,20),
      num_labels: 1,
      printer: null,
      type_label: '',
      type_labels: ['', 'COA', 'Lab Analyse', 'CQMA analyse']
    }
  },
  mounted() {
    this.getPrinter()
  },
  computed: {},
  methods: {
    ...mapActions(['setErrorMessage']),
    getPrinter(){
      console.log('...getPrinter ');
      const Dymo = require('dymojs')
      const dymo = new Dymo();

      dymo.getPrinters().then((xml)=> {
        console.log(' ... ... reading printers ');
        console.log(xml);

        const printerName = /<Name>(.*)</.exec(xml)[1].split('<')[0];
        dymo.printerName = printerName;

        console.log('printer name : ' + printerName);

        this.printer = dymo;
      })
    },
    onSelectedLabelType(v){
      this.type_label = v.value;
    },
    onSelectList(v){
      this.num_labels  = v.value;
    },
    printAllLabels(){

      if (Array.isArray(this.data)){
        this.data.forEach((v) => {
           for(let i=0; i < this.num_labels; i++)
           {
             setTimeout(() =>  this.printLabel(v) , 2000);
           }
        })
      }
      else {
        for(let i=0; i < this.num_labels; i++) {
          setTimeout(() =>  this.printLabel(this.data) , 2000);
        }
      }
    },
    printLabel(data){
      console.log(data);
      if (this.printer != null) {
        axios.get('/Labels.xml')
            .then(response => {
              let patternLabel = response.data;


              patternLabel = patternLabel.replaceAll('#PMI_CODE#', data.pmi_code);
              patternLabel = patternLabel.replaceAll('#PMI_NAME#', data.pmi_name);
              patternLabel = patternLabel.replaceAll('#BATCH#', data.batch);
              patternLabel = patternLabel.replaceAll('#PPO#', data.PPO);
              patternLabel = patternLabel.replaceAll('#P_TYPE#', data.production_type);
              patternLabel = patternLabel.replaceAll('#S_TYPE#', data.sample_type);
              patternLabel = patternLabel.replaceAll('#COA#', this.type_label);
              patternLabel = patternLabel.replaceAll('#LOCATION#', data.location);

              let p_date = "N/A";
              if (data.production_date != null){
                p_date =  moment(data.production_date).format('DD-MMM-YYYY');
              }
              patternLabel = patternLabel.replaceAll('#P_DATE#', p_date);

              let e_date = "N/A";
              if (data.expiration_date != null){
                e_date =  moment(data.expiration_date).format('DD-MMM-YYYY');
              }
              patternLabel = patternLabel.replaceAll('#E_DATE#', e_date);
              patternLabel = patternLabel.replaceAll('#E_BC_DATE#',
                                    moment(data.expiration_date).format('YYYY-MM-DD'));


              this.printer.print(this.printer.printerName, patternLabel);

            });
      }
      else{
        console.log(' ...printer is NULL, review connectivity');
        this.setErrorMessage({ data: ' ...printer is NULL, review connectivity' } );
      }
    }
  }
}

</script>
