<template>
  <b-container >
    <b-row>
      <b-col lg="5"><label class="text-sm-left" >{{label}}:</label></b-col>
      <b-col><b-form-select
          :id="'input-' + var_name"
          v-model="in_value"
          :disabled="!editable"
          :state="validation_entry"
          :options="list"
          @change="onChangedValue"
      ></b-form-select>
      </b-col>
    </b-row>
  </b-container>

</template>

<script>

import utils from '@/utils'

export default {
  props: {
    edit: Boolean,
    optional: Boolean,
    label: String,
    var_name: String,
    var_value: String,
    list: Array
  },
  data() {
    return {
      editable: this.edit,
      in_value: this.var_value,
      name: this.var_name
    }
  },
  computed: {
    validation_entry() {
      return this.optional || utils.isNotUndefined(this.in_value)
    }
  },
  methods: {
    onChangedValue () {

      this.$emit('onChangedValue', { name: this.var_name, value: this.in_value} )
    }
  },
  watch: {
    edit: function (newVal) { this.editable = newVal },
    var_value: function (newVal) { this.in_value = newVal }
  },
}
</script>
