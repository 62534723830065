<template>
  <div>
    <b-form @submit="onSubmit" @reset="onReset">
      <div class="d-flex flex-row-reverse">
        <b-button variant="primary"
                  v-if="!editable"
                  v-on:click="setEditable()">Edit</b-button>
      </div>
      <b-alert variant="danger" :show="isDuplicated"> Flavor already exists ! , please update quantity on existing item.</b-alert>

      <br/>
      
      <inputTextform :edit="editable"
                 v-bind:label="'PMI CODE'"
                 v-bind:var_name="'pmi_code'"
                 v-bind:var_value="form.pmi_code"
                 v-bind:optional="false"
                 @onChangedValue="onChangedValue"
      />

      <inputTextform :edit="editable"
                 v-bind:label="'PMI NAME'"
                 v-bind:var_name="'pmi_name'"
                 v-bind:var_value="form.pmi_name"
                 v-bind:optional="false"
                 @onChangedValue="onChangedValue"
      />

      <inputTextform :edit="editable"
                 v-bind:label="'BATCH'"
                 v-bind:var_name="'batch'"
                 v-bind:var_value="form.batch"
                 v-bind:optional="true"
                 @onChangedValue="onChangedValue"
      />

      <inputSelect :edit="editable"
                   v-bind:label="'STATUS'"
                   v-bind:var_name="'status'"
                   v-bind:var_value="form.status"
                   v-bind:optional="true"
                   v-bind:list="list_status"
                   @onChangedValue="onChangedValue"
      />

      <inputNumberform :edit="editable"
                 v-bind:label="'QUANTITY'"
                 v-bind:var_name="'quantity'"
                 v-bind:var_value="form.quantity"
                 v-bind:optional="true"
                 @onChangedValue="onChangedValue"
      />

      <inputTextform :edit="editable"
                 v-bind:label="'PPO'"
                 v-bind:var_name="'PPO'"
                 v-bind:var_value="form.PPO"
                 v-bind:optional="true"
                 @onChangedValue="onChangedValue"
      />


      <inputSelect :edit="editable"
                   v-bind:label="'SAMPLE TYPE'"
                   v-bind:var_name="'sample_type'"
                   v-bind:var_value="form.sample_type"
                   v-bind:optional="true"
                   v-bind:list="list_sample_type"
                   @onChangedValue="onChangedValue"
      />


      <inputSelect :edit="editable"
                   v-bind:label="'PRODUCTION TYPE'"
                   v-bind:var_name="'production_type'"
                   v-bind:var_value="form.production_type"
                   v-bind:optional="true"
                   v-bind:list="list_production_type"
                   @onChangedValue="onChangedValue"
      />

      <inputDate :edit="editable"
                 v-bind:label="'PRODUCTION DATE'"
                 v-bind:var_name="'production_date'"
                 v-bind:var_value="form.production_date"
                 v-bind:optional="true"
                 @onChangedValue="onChangedValue"
      />

      <inputDate :edit="editable"
                 v-bind:label="'EXPIRE DATE'"
                 v-bind:var_name="'expiration_date'"
                 v-bind:var_value="form.expiration_date"
                 v-bind:optional="true"
                 @onChangedValue="onChangedValue"
      />

      <inputSelect :edit="editable"
                 v-bind:label="'LOCATION'"
                 v-bind:var_name="'location'"
                 v-bind:var_value="form.location"
                 v-bind:optional="true"
                 v-bind:list="list_location"
                 @onChangedValue="onChangedValue"
      />

      <inputNumberform :edit="editable"
                 v-bind:label="'NUM.CONTAINERS'"
                 v-bind:var_name="'container'"
                 v-bind:var_value="form.container"
                 v-bind:optional="true"
                 @onChangedValue="onChangedValue"
      />

      <inputTextform :edit="editable"
                 v-bind:label="'COMMENT'"
                 v-bind:var_name="'comment'"
                 v-bind:var_value="form.comment"
                 v-bind:optional="true"
                 @onChangedValue="onChangedValue"
      />

      <inputCheckbox :edit="editable"
                     v-bind:label="'REFERENCE'"
                     v-bind:var_name="'reference'"
                     v-bind:var_value="form.reference"
                     v-bind:optional="true"
                     @onChangedValue="onChangedValue"
      />


      <br/>

      <div class="d-flex flex-row-reverse" v-if="!user.roles.isViewer && editable">

        <b-button type="submit" v-if="isValidInputData()" variant="primary">{{submitButtonText}}</b-button>
        <b-button type="reset" variant="danger">CLEAR</b-button>

      </div>
    </b-form>
  </div>
</template>

<style scoped>

.form-control {
  padding: 2px;
}

.container {
  padding-bottom: 5px;
}
</style>

<script>

import utils from '@/utils'
import inputTextform from '@/views/components/forms/Form.input.text'
import inputNumberform from '@/views/components/forms/Form.input.number'
import inputSelect from '@/views/components/forms/Form.input.select'
import inputDate from '@/views/components/forms/Form.input.date'
import inputCheckbox from '@/views/components/forms/Form.input.checkbox'
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  components: {
    inputTextform,
    inputNumberform,
    inputSelect,
    inputDate,
    inputCheckbox
  },
  props: {
    edit: Boolean,
    data: Object,
   // user: String
  },
  data() {
    return {
      isDuplicated: false,
      editable: this.edit,
      form: {
        pmi_code: this.data.pmi_code,
        pmi_name: this.data.pmi_name,
        batch: this.data.batch,
        status: this.data.status,
        quantity: Number(this.data.quantity) || 0,
        PPO: this.data.PPO,
        sample_type: this.data.sample_type,
        production_type: this.data.production_type,
        location: this.data.location,
        production_date: this.data.production_date,
        expiration_date: this.data.expiration_date,
        container: this.data.container,
        comment: this.data.comment,
        reference: this.data.reference || false
      },
      show: true,
      submitButtonText: utils.isBlank(this.data)?'ADD':'UPDATE'
    }
  },
  computed: {
    ...mapState(['list_of_lists', 'user', 'flavor_list']),
    ...mapGetters(['list_of_lists', 'user', 'flavor_list']),
    validation() {
      return this.validation_pmicode && this.validation_name
    },
    validation_pmicode() {
      return utils.isNotUndefined(this.form.pmi_code) && this.form.pmi_code.length >= 3
    },
    validation_name() {
      return utils.isNotUndefined(this.form.pmi_name) && this.form.pmi_name.length >= 3
    },
    validation_batch() {
      return utils.isNotUndefined(this.form.batch) && this.form.batch.length >= 3
    },
    validation_expiration_date() {
      return utils.isNotUndefined(this.form.expiration_date)
    },
    list_location(){
      return this.getListByName('Location').values
    },
    list_production_type(){
      return this.getListByName('Production Type').values
    },
    list_sample_type(){
      return this.getListByName('Sample Type').values
    },
    list_status(){
      return this.getListByName('Sample Status').values
    }

  },
  mounted() {
    this.getLists()
  },
  methods: {
    ...mapActions(['getLists', 'getFlavorLists']),
    isValidInputData() { return this.validation && !this.isDuplicated ;  },
    viewIfExistsFlavor() {
      if (utils.isEmpty(this.data)
          && !utils.isEmpty(this.form.sample_type)
          && this.validation_batch
          && this.validation_expiration_date)
      {
        const current = {
          pmi_code: this.form.pmi_code,
          batch: this.form.batch,
          expiration_date: this.form.expiration_date,
          sample_type: this.form.sample_type
        }
        console.log(current)
        this.getFlavorLists(current).then((v) => {
          this.isDuplicated = (v.length>0);
        });
      }
    },
    getListByName(name) {  return this.list_of_lists.filter(x => x.name==name)[0]  },
    setEditable() { this.editable = true; },
    onChangedValue(v){
      this.form[v.name] = v.value
      this.viewIfExistsFlavor();
    },
    onSubmit(event) {
      event.preventDefault()
      var closeFlavorModal = false;
            
      if (this.form.reference && this.submitButtonText == 'ADD') {
          var flavorListForNoReference = this.flavor_list.filter(x => x.pmi_code == this.form.pmi_code && x.pmi_name == this.form.pmi_name && x.reference == false)
          if (utils.isNotUndefined(flavorListForNoReference) && flavorListForNoReference.length > 0)
              this.$alert('A Flavor already exists with same PMI Code and PMI Name but has Reference unchecked.', 'Alert', 'warning');
      }
            
      if (!this.form.reference && this.submitButtonText == 'ADD') {
         var flavorListForReference = this.flavor_list.filter(x => x.pmi_code == this.form.pmi_code && x.pmi_name == this.form.pmi_name && x.reference == true)
         if (utils.isNotUndefined(flavorListForReference) && flavorListForReference.length > 0) {
              this.form["reference"] = true;
              closeFlavorModal = true;
         }
      }

      const payload = { form: this.form, previous: this.data, user: this.user.login, reason: '' }
      this.$store.dispatch('saveFlavor', payload);
      if (!utils.isEmpty(this.data) || closeFlavorModal) this.$emit('closeModal', this.form.quantity);
      this.$emit('refreshTable');
      
    },
    onReset(event) {
      event.preventDefault()
      this.form.code_type=null
      this.form.pmi_code=null
      this.form.pmi_name=null
      this.form.batch=null
      this.form.status=null
      this.form.quantity=0
      this.form.PPO=null
      this.form.sample_type=null
      this.form.production_type=null
      this.form.location=null
      this.form.production_date=null
      this.form.expiration_date=null
      this.form.container=null
      this.form.comment=null
      this.show = false
      this.reference = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>
