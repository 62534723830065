<template>
  <b-container >
    <b-row>
      <b-col lg="5"><label class="text-sm-left" >{{label}}:</label></b-col>
      <b-col><b-form-datepicker
          :id="'input-' + var_name"
          v-model="in_value"
          :disabled="!editable"
          :state="validation_entry"
          @context="onContext"
      ></b-form-datepicker></b-col>
    </b-row>
  </b-container>

</template>

<script>

import utils from '@/utils'

export default {
  props: {
    edit: Boolean,
    optional: Boolean,
    label: String,
    var_name: String,
    var_value: Date
  },
  data() {
    return {
      editable: this.edit || false,
      in_value: this.var_value,
      name: this.var_name
    }
  },
  computed: {
    validation_entry() {
      return this.optional || utils.isNotUndefined(this.in_value)
    }
  },
  methods: {
    onContext () {
      // console.log('...context')
      // console.log(ctx) // as parameter of the function call
      const payload = { name: this.var_name, value: this.in_value}
      //console.log(payload)
      this.$emit('onChangedValue', payload )
    }
  },
  watch: {
    edit: function (newVal) { this.editable = newVal }
   // var_value: function (newVal) { this.in_value = new Date(newVal) }
  },
}
</script>
