<template>
  <b-container fluid>

    <loading loader="spinner"
             :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"></loading>

    <!-- Info modal -->
    <b-modal
             :id="flavorModal.id"
             :title="flavorModal.title"
             :hide-header="true"
             :hide-footer="true"
             @hide="resetInfoModal"
            >
        <FlavorForm :edit="flavorModal.editable"
                    :data="flavorModal.content"
                    v-on:refreshTable="refreshTable"
                    v-on:closeModal="closeInfo"/>
    </b-modal>

    <!-- Labels modal -->
    <b-modal
        :id="labelModal.id"
        :title="labelModal.title"
        :hide-header="true"
        :hide-footer="true"
      >

      <Labels :data="labelModal.content"
                  v-on:closeModal="closeLabel"/>
    </b-modal>

    <!-- TABLE CONTENT -->
    <b-table
        ref="table"

        :items="filtered"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :tbody-tr-class="rowClass"
        stacked="md"
        show-empty
        small
        striped
        hover

        @filtered="onFiltered"
    >
      <template #head()="data"  >
        <span class="text-info">{{ data.label.toUpperCase() }}</span>
      </template>

      <template slot="top-row" slot-scope="{ fields }">
        <td v-for="field in fields" :key="field.key">
          <input class="filter-input"
                 v-model="filters[field.key]"
                 :hidden="field.key=='actions'">
          <b-col :hidden="field.key!='actions'" id="row-actions" >
            <b-icon-plus-circle
                title="Add a new Flavor"
                :hidden="field.key!='actions'"
                class="cursor-pointer"
                @click="newFlavor($event.target)"></b-icon-plus-circle>
            &nbsp;
          <b-icon-printer-fill
              title="Print selected flavors"
              :hidden="field.key!='actions' || selected.length==0"
              class="cursor-pointer"
              @click="printSelectedFlavors($event.target)"></b-icon-printer-fill>
          </b-col>

        </td>
      </template>

      <template #cell(actions)="row">
        <b-col>
            <input type="checkbox" :value="row.item" v-model="selected">
          &nbsp;
            <b-icon-pencil  class="cursor-pointer"
                            @click="editFlavor(row.item, row.index, $event.target)"></b-icon-pencil>
          &nbsp;
            <b-icon-printer  class="cursor-pointer"
                             @click="printLabel(row.item, row.index, $event.target)"></b-icon-printer>
           &nbsp;
            <b-icon-save class="cursor-pointer"
                           @click="saveAsFlavor(row.item, row.index, $event.target)"></b-icon-save>

        </b-col>

      </template>

    </b-table>

    <!-- PAGINATION -->

    <div class="d-flex justify-content-center">

      <multiselect  v-model="status_selected"
                    class="sample-status"
                    :multiple="true"
                    :options="sample_status"
                    @input="filterByStatus"
      ></multiselect>

      <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          size="sm"
          align="fill"
      ></b-pagination>

      &nbsp;&nbsp;&nbsp;&nbsp;

      Per page

      &nbsp;

      <b-form-select style="height: 25px"
          v-model="perPage"
          :options="pageOptions"
          size="sm"
      ></b-form-select>
    </div>

  </b-container>
</template>

<style>
.sr-only {display: none;}
.filter-input {
  width: 120px !important;
}
.cursor-pointer{
  cursor: pointer;
}

.expired {
  font-weight: bold;
  color: red !important;
}

.close-to-expired {
  font-weight: bold;
  color: orange !important;
}
.sample-status {
  width: 200px;
  max-width: 400px;
  margin-right: 150px;
}

.page-item.active .page-link {
  height: 31px;
}

</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>

import { mapState, mapGetters, mapActions } from 'vuex'
import FlavorForm from '@/views/components/Flavor.Form'
import Labels from "@/views/components/Labels";
import moment from 'moment';
import utils from '@/utils';
import Multiselect from 'vue-multiselect';
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'PageReception',
  components:{
    Multiselect,
    FlavorForm,
    Labels,
    loading: VueLoading
  },
  data() {
    return {
      isLoading: true,
      selected: [],
      fields : [
        { key: 'actions', label: '' },
        { key: 'pmi_code', label: 'PMI CODE', sortable: true },
        { key: 'pmi_name', label: 'PMI NAME', sortable: true },
        { key: 'batch', label: 'BATCH', sortable: true },
        { key: 'status', label: 'STATUS', sortable: true },
        { key: 'quantity', label: 'QUANTITY', sortable: true },
        { key: 'PPO', label: 'PPO', sortable: true },
        { key: 'sample_type', label: 'SAMPLE TYPE', sortable: true },
        { key: 'production_type', label: 'PRODUC.TYPE', sortable: true },
        { key: 'production_date', label: 'PRODUC.DATE', sortable: true, formatter: 'getFormatDate' },
        { key: 'expiration_date', label: 'EXP.DATE', sortable: true , formatter: 'getFormatDate'},
        { key: 'location', label: 'LOCATION', sortable: true }

      ],
      filters: {
        pmi_code: '',
        pmi_name: '',
        batch: '',
        status: '',
        quantity: '',
        PPO: '',
        sample_type: '',
        production_type: '',
        location: '',
        expiration_date: ''
      },
      status_selected: [],
      sample_status: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 1000,
      pageOptions: [15, 30, 60, 120, { value: 1000, text: "Show a lot" }],
      sortBy: 'pmi_code',
      sortDesc: false,
      sortDirection: 'asc',
      flavorModal: {
        id: 'flavor-modal',
        title: '',
        content: {},
    //    user: '',
        editable: false
      },
      labelModal: {
        id: 'label-modal',
        title: '',
        content: {}
      }
    }
  },
  computed: {
    ...mapState(['user','flavor_list', 'list_of_lists']),
    ...mapGetters(['user','flavor_list', 'list_of_lists']),
    filtered() {
      const filtered = this.flavor_list.filter(item => {
        return Object.keys(this.filters).every(key =>
            String(item[key]).toLowerCase().includes(this.filters[key].toLowerCase()) ||
            String(this.getFormatDate(item[key])).toLowerCase().includes(this.filters[key].toLowerCase())
        )
    })
      return filtered.length > 0 ? filtered : [{
        pmi_code: '',
        pmi_name: '',
        batch: '',
        status: '',
        quantity: '',
        PPO: '',
        sample_type: '',
        production_type: '',
        location: '',
        expiration_date: ''
      }]
    }
  },
  mounted() {
    this.getLists()
        .then(this.init)
        .then(this.getUser)
        .then(() => { this.isLoading=false; })
  },
  methods: {
    ...mapActions(['getUser','getFlavorLists', 'getLists']),
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (moment(item.expiration_date, 'YYYY-MM-DD').diff(new Date(), 'days') < 0) return 'expired'
      if (moment(item.expiration_date, 'YYYY-MM-DD').diff(new Date(), 'months') < 3) return 'close-to-expired'
    },
    getFormatDate(date){
      return (utils.isNotUndefined(date))? moment(date, 'YYYY-MM-DD').format('DD MMM YYYY'): '';
    },
    closeInfo() {
      this.$root.$emit('bv::hide::modal', this.flavorModal.id)
    },
    refreshTable(){
      console.log(' ...refreshTable was called ');
      setTimeout(() => {
        this.flavor_list = this.getFlavorLists({'status': (this.status_selected || ["none"])})
        this.totalRows = this.flavor_list.length;
        this.$forceUpdate();
      },1000, this);
    },
    closeLabel() {
      this.$root.$emit('bv::hide::modal', this.labelModal.id)
    },
    editFlavor(item, index, button) {

      this.flavorModal.content = item
      this.flavorModal.editable = true
     // this.flavorModal.user = this.user.user
      this.$root.$emit('bv::show::modal', this.flavorModal.id, button)
    },
    saveAsFlavor(item, index, button) {
            
      item["PPO"] = null;
      item["batch"] = null;
      item["status"] = null;
      item["comment"] = null;
      item["location"] = null;
      item["quantity"] = 0;
      item["container"] = null;
      item["sample_type"] = null;
      item["expiration_date"] = null;
      item["production_date"] = null;
      item["production_type"] = null;
      item["_id"] = null;
     
      this.flavorModal.content = item
      this.flavorModal.editable = true
      // this.flavorModal.user = this.user.user
      this.$root.$emit('bv::show::modal', this.flavorModal.id, button)
    },
    printLabel(item, index, button){
      this.labelModal.content = item;
      this.$root.$emit('bv::show::modal', this.labelModal.id, button)

    },
    newFlavor(button){
      this.flavorModal.content = {}
      this.flavorModal.editable = true
    //  this.flavorModal.user = this.user.user
      this.$root.$emit('bv::show::modal', this.flavorModal.id, button)
    },
    printSelectedFlavors(button){
      this.labelModal.content = this.selected;
      this.$root.$emit('bv::show::modal', this.labelModal.id, button)
    },
    resetInfoModal() {
      this.flavorModal.title = ''
      this.flavorModal.content = {}
      this.refreshTable();
    //  this.flavorModal.user = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async filterByStatus() {
      if (!utils.isEmpty(this.status_selected)) {
        localStorage.setItem("defaultReceptionList", JSON.stringify([...this.status_selected] ));
        await this.getFlavorLists({'status': this.status_selected})
      }
      else await this.getFlavorLists({'status': ["none"]})
    },
    init(){
      this.sample_status = this.list_of_lists.filter(x => x.name == 'Sample Status')[0].values;
      this.status_selected = JSON.parse(localStorage.getItem("defaultReceptionList"));
      this.filterByStatus();

      setTimeout(() => {
        this.totalRows = this.flavor_list.length;
        this.$forceUpdate();
      },2000);

    }
  }
}
</script>
